import { defineAsyncComponent } from 'vue'
export default {
  blog: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/blog.vue").then(m => m.default || m)),
  "bootcamp-register": defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/bootcamp-register.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/default.vue").then(m => m.default || m)),
  event: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/event.vue").then(m => m.default || m)),
  full: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/full.vue").then(m => m.default || m)),
  landing: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/landing.vue").then(m => m.default || m)),
  "page-card": defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/page-card.vue").then(m => m.default || m)),
  "page-narrow": defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/page-narrow.vue").then(m => m.default || m)),
  page: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/page.vue").then(m => m.default || m)),
  solutions: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/solutions.vue").then(m => m.default || m)),
  template: defineAsyncComponent(() => import("/mnt/scratch/pathwaytester/.jenkins/workspace/website_develop/public/website3/layouts/template.vue").then(m => m.default || m))
}